.g_phai{
    margin-left: 5px;
}
.div_avatar {
    display: flex;
    justify-content: center;
    
}
.avatar_nd {
    margin-top: 20px;
}
.text_name {
    margin-top: 10px;
    text-align: center;
    color: #121828;
}
.div_text_trai {
    margin-top: 11px;
}
.text_username {
    text-align: center;
    line-height: 30px;
    color: #65748b;
}
.button_anhdaidien {
    display: flex;
    justify-content: center;
    border: 1px solid #ddd;
}
.div_btn_update{
    display: flex;
    justify-content: right;
}
.changePass_div {
    display: flex;
    justify-content: right;
}
@media screen and(max-width: 600px){
    .g_trai{
        width: 100%;
    }
}