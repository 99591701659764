.search_box {
    display: flex;
    align-items: center;
    margin-left: 20px;
    justify-content: right;
}

.span_text {
    color: whitesmoke;
    white-space: nowrap;
}

.span_text:hover {
    color: black;
}

.span_text:active {
    color: black;
}

.OrganizaTionPage_cop {
    display: flex;
    justify-content: center;
}

.logo_troot {
    width: 10vw;
    height: 20vh;
}

@media screen and (max-width:600px) {
    .btn_addUser {
        width: 50vw;
        height: 5vh;
        color: white;
    }

    .search_box {
        margin-left: 10px;
    }

    .OrganizaTionPage_cop {
        width: 100%;
        height: 100%;
    }

    .logo_troot {
        width: 40%;
        height: 40%;
    }
}