.divanh {
    display: flex;
    width: 35%;
}

.boxlon {
    margin-left: 20vh;
    width: 80%;
    height: 80%;
}

.divtong {
    margin-top: 4vh;
}

.divLogin {
    width: 65%;
    height: 100%;
}
.sms_otp{
    margin-top: 50px;
}
@media screen and (max-width:900px) {
    .boxlon {
        margin-left: 20vh;
        width: 100%;
        height: 100%;
    }

    .text_troot {

        display: flex;
        justify-content: center;
        text-align: center;
    }

    .divanh {
        display: none;
    }

    .divLogin {
        width: 100%;
        height: 100%;
    }

    .boxlon {
        margin-left: 0px;
    }

    .sms_otp {
        width: 50vw;
        text-align: right;
        font-size: medium;
        margin-top: 10px;
    }

    .forgotPass {
        width: 50vw;
    }
    .inputOTP{
        display: none;
    }
   
}