.root{
    width: 100%;
    height: 100%;
}
.logo_st{
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 1rem;
}
.welcomeText{
    text-align: center;
}
p{
    text-align: center;
}
Link{
    text-decoration: none;
}