.ttSP_div{
    display: flex;
}
.btn_submit{
    display: flex;
    justify-content: right;
}
.button_ky {
    display: flex;
    justify-content: right;
}