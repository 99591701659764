.avatar {
    margin-left: 0.5vw;
    margin-top: -1vh;
    width: 100%;
    height: 100%;
}

.div_tennd {
    margin-top: -2vh;
    width: 40%;
}

.div_email {
    margin-top: -2vh;
    margin-left: 2vw;
    width: 50%;
}
.div_sdt{
    width: 100%;
    margin-top: 1vh
}
.box_nd{
    margin-left: 4vw;
}
.box_dongduoi {
    display: flex;
}
.select_vt {
    align-items: center;
    margin-top: 1vh;
    margin-left: 2vw;
    width: 50%;
}
.div_vaitro{
    flex-direction:column;
    width: 50%;
}