.input_phone {
    width: 25vw;
}

.recaptcha-container1 {
    margin-left: 40vw;
    margin-top: 2vh;
}

@media screen and (max-width:600px) {
    .imgChiaKhoa {
        width: 100%;
        height: 90%;
        margin-left: -5vw;
    }

    .input_phone {
        width: 80vw;
    }
    .recaptcha-container1 {
            margin-left: 9vw;
            margin-top: 2vh;
    }
}