.backgroud_div{
    background-image: url('../image/resetpass_bg.jpg');
    display: flex;
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0
}
.loginImage {
    width: 30%;
    height: 25%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
