.anhNen{
    width: 100%;
    height: 100%;
    margin-top: 5em;
    margin-left: 5em;
}
.divlon{
    width: 100%;
    height: 100%;
    display: flex;
}
.divanh{
    width: 40%;
    height: 40%;
}
.loginImage{
    width: 40%;
    height: 40%;
}