.div_slqt {
    display: flex;
    align-items: center;
}

.div_addSP {
    display: flex;
    justify-content: right;
}

.root {
    margin-left: 5px;
}

.div_lcdvkd {
    display: flex;
    flex-direction: column;
}

.div_tenqt {
    display: flex;
    flex-direction: column;
}

.div_mota {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin-top: 12px;
}
.editor {
    border: 1px solid #ddd;
}