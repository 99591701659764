.imageLon{
    width: 100%;
    height: 100%;
}
.imageTrong{
    width: 30em;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 1rem;
}
