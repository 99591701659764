.header_mota {
    margin-top: 10px;
    font-size: 15;
    text-align: left;
}

.header_gioithieu {
    font-size: 14;
    text-align: left;
}

.div_top1 {
    width: 40%;
    display: flex;
    flex-direction: column;
}

.div_top2 {
    width: 45%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
}

.div_trencung {
    margin-left: 2%;
    width: 100;
    display: flex;
}

.div_tren3s {
    display: flex;
    width: 100%;
    margin-left: 2%;
    margin-top: 5px;
}

.div_thsds {
    width: 39.5%;
    display: flex;
    flex-direction: column;
}

.div_los {
    width: 44.5%;
    display: flex;
    flex-direction: column;
    margin-left: 4.5%;
}

.div_lohang {
    margin-top: 5px;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    width: 89%;
}
.div_motahtml{
    margin-left: 2%;
    width: 89%;
    margin-top: 5px;
}
.editor {
    min-height: 6em;
}
.div_hinhanh{
    margin-left: 2%;
    margin-top: 10px;
}
.button_submit{
    display: flex;
    justify-content: right;
}