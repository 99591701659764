.div_3 {
    background-color: #63bd6f;
    border-radius: 10px;
}

.ribbon {
    display: flex;
}

.grid_trai {
    width: 25vw;
    height: 30vh;
}

.banner {
    width: 100%;
    height: 37vh;
}

.ribbonTrai {
    width: 50%;
}

.ribbonPhai {
    width: 50%;
}

.star {
    display: flex;
    justify-content: center;
    margin-top: -30px;
}

.div2_star {
    background-color: #54b862;
    color: white;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    width: 70px;
    height: 70px;
}

.star_icon {
    display: flex;
}

.divChu {
    margin-top: 2vw;
    padding-bottom: 2vh;
}

.grid_phai {
    background-image: url('../image/backgroud_phai.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 34vh;
    width: 60%;
    border-radius: 10px;
    margin-left: 3vw;
}

.div_grid_phai {
    margin-left: 20px;
    color: #fbc658;
    margin-top: 2vh;
}

.text_spgt {
    color: white;
    margin-top: 10px;
    font-size: 16pt;
}

.spanTroot {
    color: #54b862;
    cursor: pointer;

    transition: 0.1s all ease;
}

.spanTroot:hover {
    color: #64da73;
}
@media screen and (max-width: 600px) {
    .grid_trai {
        width: 100%;
        height: 100%;
    }

    .grid_phai {
        margin-top: 2vh;
        width: 100vw;
        height: 100%;
        padding-bottom: 3vh;
        margin-left: 0;
    }
}