.div_tren1 {
    margin-top: 20px;
    display: flex;
    width: 100%;
}

.div_content {
    margin-left: 15px;
}

.div_tensp {
    width: 45%;
}

.div_loaisp {
    margin-left: 5%;
    width: 45%;
}

.div_giaban {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    width: 45%;
}

.div_donviban {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    width: 45%;
    margin-top: 12px;
}

.div_tren2 {
    display: flex;
}

.div_thsd {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.div_lo {
    display: flex;
    flex-direction: column;
    width: 45%;
    margin-left: 5%;
}

.div_tren3 {
    display: flex;
    margin-top: 12px;

}

.div_mota {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin-top: 12px;
}

.editor {
    border: 1px solid #ddd;
}

.img_1 {
    width: 150px;
    height: 150px;
}

.div_hinhanhsp {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 600px) {
    .div_content {
        margin-left: 5px;
    }

    .div_tren1 {
        display: block;
        width: 100%;
    }

    .div_tensp {
        width: 100%;
    }

    .div_mota {
        width: 100%;
    }

    .div_loaisp {
        margin-left: 0;
        width: 100%;
    }

    .div_tren2 {
        display: block;
    }

    .div_giaban {
        width: 100%;
        display: column;
    }

    .div_donviban {
        width: 100%;
        margin-left: 0;
    }

    .div_thsd {
        width: 45%;
    }

    .div_lo {
        width: 50%;
    }
}